'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useOnClickOutside } from 'usehooks-ts';

type Option<T> = {
  icon?: string;
  label: string;
  value: T;
  latency?: number;
};

interface DropdownProps<T> {
  options: Option<T>[];
  onToggle?: (newState: boolean) => void;
  disabled?: boolean;
  selectedLabel: string;
  selectedLatency?: number;
  onSelect: (val: Option<T>) => void;
  className?: string;
}

export const RpcSelectorDropdown = <T,>({
  options,
  onToggle,
  disabled,
  selectedLabel,
  selectedLatency,
  onSelect,
  className,
}: DropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>,
    ],
    () => setOpen(false)
  );

  const opts = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'right-start'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          altAxis: true,
        },
      },
    ],
    placement: 'top-end' as const,
  };

  const { styles, attributes } = usePopper(referenceElement, popperElement, opts);

  const handleOptionClick = (option: Option<T>) => {
    onSelect(option);
    setOpen(false);
  };

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open, onToggle]);

  return (
    <div className={clsx(className, 'text-neutral-500 z-50')}>
      <button
        ref={setReferenceElement as React.LegacyRef<HTMLButtonElement>}
        disabled={disabled}
        onClick={() => setOpen(!open)}
        type="button"
        className={clsx(
          'flex items-center p-2 font-medium border  bg-neutral-900',
          `rounded-full xl:rounded-lg xl:w-[120px] border-neutral-600 hover:border-neutral-500`,
          disabled && 'opacity-40 pointer-events-none'
        )}
        id="options-menu"
        aria-haspopup="listbox"
      >
        <div className="flex justify-between xl:w-full">
          <div className="hidden xl:flex">
            <span className="text-neutral-100 font-normal whitespace-nowrap overflow-hidden overflow-ellipsis text-xs">
              {selectedLabel}
            </span>
            <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-xs ml-auto">
              {selectedLatency ? `${selectedLatency.toFixed()} ms` : null}
            </span>
          </div>
          <Image src={'/down_arrow.svg'} alt="svg" width={18} height={18} className={clsx(open && 'rotate-180')} />
        </div>
      </button>

      <AnimatePresence>
        {open && (
          <div ref={setPopperElement as React.LegacyRef<HTMLDivElement>} style={styles.popper} {...attributes.popper}>
            <motion.ul
              initial={{ opacity: 0, transform: 'translateY(20px)' }}
              animate={{ opacity: 1, transform: 'translateY(0px)' }}
              exit={{ opacity: 0 }}
              role="listbox"
              aria-labelledby="options-menu"
              aria-activedescendant="selected-option"
              className="py-1 w-full rounded-lg border border-neutral-600 bg-neutral-900"
            >
              {options.map((option, ix) => (
                <li
                  key={`${option.label}-${ix}`}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer select-none relative px-2 py-2 flex items-center"
                >
                  <div className="flex items-center space-x-2 w-full">
                    {option.icon && <Image src={option.icon} alt="svg" width={24} height={24} className="ml-auto" />}
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-xs">
                      {option.label}
                    </span>
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-xs ml-auto">
                      {option.latency ? `${option.latency?.toFixed()} ms` : null}
                    </span>
                  </div>
                </li>
              ))}
            </motion.ul>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
